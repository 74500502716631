export default class Tenant {
    name;
    id;
    logo;
    vrm;
    accessScreens;
    locations;

    /**
     * Model for Tenant
     * @param {*} params - Params to initialize
     */
    constructor({
        name = "",
        id = "",
        logo = "",
        vrm = null,
        accessScreens = [],
        locations = []
    }) {
        this.name = name;
        this.id = id;
        this.logo = logo;
        this.vrm = vrm;
        this.accessScreens = accessScreens;
        this.locations = locations;
    }
}