import mqtt from "mqtt";
import PropTypes from "prop-types";
import { load } from "protobufjs";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import config from "../config";

const MQTTContext = createContext(null);
/**
 * Use MQTT Context
 * @returns {Element} MQTT Context Values
 */
export const useMQTT = () => useContext(MQTTContext);

/**
 * MQTT Context Provider
 * @param {*} props - Props
 * @returns {Element} - MQTT Provider
 */
export const MQTTProvider = ({ children }) => {
    const [client, setClient] = useState(null);
    const [connected, setConnected] = useState(false);
    const [protoDecoder, setProtoDecoder] = useState();
    const [bikeMQTTData, setBikeMQTTData] = useState({});
    
    const clientSetupDone = useRef(false);
    const topics = useRef([
        "/tennants/staging/egym/metrics",
        "/tennants/staging/egym/status",
        "/tennants/staging/tablet/status/#"
    ]);

    useEffect(() => {
        /**
         * Connect to MQTT
         * @param {string} host - MQTT Host
         * @param {object} mqttOption - MQTT Options
         */
        const mqttConnect = (host, mqttOption = null) => {
            setConnected(false);
            setClient(mqtt.connect(host, mqttOption));
        };

        if (!connected) {
            mqttConnect(config.env("REACT_APP_MQTT_DOMAIN"), {
                "password": config.env("REACT_APP_MQTT_PASSWORD"),
                "username": config.env("REACT_APP_MQTT_USERNAME")
            });
        }
    }, [connected]);
    
    useEffect(() => {
        /**
         * Sets proto root object
         */
        const getProtoRoot = (async () => {
            const root = await load("/messages.proto");

            setProtoDecoder({
                "metric": root.lookupType("Messages.Metric"),
                "status": root.lookupType("Messages.Status")
            });
        });

        /**
         * Extracts mac from object and converts to hex string
         * @param {object} data - MQTT Data
         * @returns {object} MQTT Data with Bike ID
         */
        const extractBikeId = (data) => {
            const mac = data.header.mac;
            const bikeId = Array.from(mac).splice(0, 6)
                .map((i) => i.toString(16).padStart(2, "0").toUpperCase())
                .join(":");

            return {
                bikeId,
                ...data
            };
        };

        /**
         * Update data of bike id
         * @param {object} data - Data
         * @param {string} bikeId - Bike ID
         * @param {string} type - Type of data
         */
        const updateBikeData = (data, bikeId, type) => {
            setBikeMQTTData((prevState) => {
                let bike = {};
                if (prevState[bikeId]) {
                    bike = JSON.parse(JSON.stringify(prevState[bikeId]));
                }

                const updatedBike = {
                    ...bike,
                    [type]: data
                };

                return {
                    ...prevState,
                    [bikeId]: updatedBike
                };
            });
        };

        if (!protoDecoder) {
            getProtoRoot();
        }

        if (client && protoDecoder && !clientSetupDone.current) {
            clientSetupDone.current = true;
            client.on("connect", () => {
                setConnected(true);

                client.subscribe(topics.current, (err, granted) => {
                    if (err) {
                        // eslint-disable-next-line no-console
                        console.error("Error while subscribing to topic", granted);
                    }
                });
            });
            client.on("error", (err) => {
                // eslint-disable-next-line no-console
                console.error("Connection error: ", err);
                client.end();
            });
            client.on("reconnect", () => {
                setConnected(false);
            });
            client.on("message", async (topic, message) => {
                try {      
                    let decodedMessage, type;
                    if (topic.endsWith("metrics")) {
                        decodedMessage = extractBikeId(protoDecoder["metric"].decode(message));
                        type = "metric";
                    } else if (topic.endsWith("status")) {
                        decodedMessage = extractBikeId(protoDecoder["status"].decode(message));
                        type = "status";
                    } else {
                        decodedMessage = JSON.parse(message.toString());
                        type = "tabletStatus";
                    }

                    updateBikeData(decodedMessage, decodedMessage?.bikeId, type);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error("Error while decoding message - ", e);
                }
            });
        }
    }, [client, protoDecoder]);

    return (
        <MQTTContext.Provider
            value={useMemo(() => {
                return {
                    bikeMQTTData,
                    connected
                };
            }, [bikeMQTTData, connected])}
        >
            {children}
        </MQTTContext.Provider>
    );
};

MQTTProvider.propTypes = {
    "children": PropTypes.node.isRequired
};