export const enLanguage = {
    "BatteryDashboard": {
        "consumption": "Consumption",
        "filter": "Filter",
        "from_grid": "From Grid",
        "to_grid": "To Grid"
    },
    "BikeTiles": {
        "appVersion": "App Version",
        "batteryPercentage": "Battery Percentage",
        "bike": "Bike",
        "bikeStatus": "Bike Status",
        "cadence": "Cadence",
        "firmwareVersion": "Firmware Version",
        "reGen": "RE:GEN",
        "uuid": "UUID"
    },
    "Bikes": {
        "actions": "Actions",
        "bt_connection_status": "BT Connection Status",
        "cancel": "Cancel",
        "changeFirmware": "Change Firmware",
        "device_id": "Device Id",
        "device_type": "Device Type",
        "expire_at": "Expire At",
        "firmware": "Firmware",
        "firmware_updated_successfully": "Firmware upgrade triggered successfully.",
        "firmware_upgrade_message": "Firmware set to upgrade to {{targetFirmware}}",
        "location_id": "Location ID",
        "name": "Name",
        "os_version": "OS Version",
        "save": "Save",
        "selectFirmware": "Select Firmware",
        "short_bike_id": "Short Bike ID",
        "tenant_id": "Tenant ID",
        "time_stamp": "Last Status Updated",
        "uuid": "UUID",
        "version": "Version"
    },
    "Dashboard": {
        "cancel": "Cancel",
        "edit": "Edit",
        "save": "Save",
        "unAllocate": "Un allocate",
        "update_bike_log": "Updated tenant id and location id successfully."
    },
    "Forbidden": {
        "title": "Forbidden"
    },
    "Header": {
        "titles": {
            "battery-dashboard": "Battery Dashboard",
            "bike": "Bike - {{bikeUuId}}",
            "bike-health": "Bike Health",
            "bikes": "Bikes",
            "gyms": "Gyms"
        }
    },
    "Login": {
        "back": "Back",
        "deActiveUser": "User has been deactivated, please contact admin",
        "email": "Email",
        "emailRequired": "Email is required",
        "notConfigureUser": "User not properly configured, please contact admin",
        "password": "Password",
        "passwordRequired": "Password is required",
        "reset": "Reset",
        "resetPassword": "Reset Password",
        "resetPasswordMsg": "Please check your email for the reset password link.",
        "title": "Login",
        "userNotHaveRoleMsg": "User does not have any roles"
    },
    "Logout": {
        "title": "Logout"
    }
};
