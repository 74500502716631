let timer;

/**
 * A debounce function that delays the execution of a given function.
 * @param {Function} func - The function to be debounced.
 * @param {number} timeout - The delay in milliseconds.
 * @returns {Function} - A debounced function.
 */
export const debounce = (func, timeout = 300) => {
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};
