import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

/**
 * TextInput component for forms.
 * @param {object} props - Component props.
 * @param {string} props.name - The name of the input field.
 * @param {*} props.defaultValue - The default value for the input field.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.variant - The variant of the input field (outlined, standard, filled).
 * @param {object} props.control - React Hook Form's control object.
 * @param {boolean} props.error - Indicates if there's an error with the input.
 * @param {boolean} props.disabled - Indicates if there's an disabled with the input.
 * @param {object} props.rules - Validation rules for the input field.
 * @param {string} props.type - The type of input (text, password, etc.).
 * @param {string} props.helperText - Helper text to display below the input.
 * @param {boolean} props.multiline - Indicates if the input is a multiline text field.
 * @param {number} props.rows - Number of rows (for multiline inputs).
 * @param {Function} props.onChange - Function to handle input changes.
 * @returns {Element} - return tool tip for Storage Location Map
 */
const TextInput = ({
    name = "",
    defaultValue = "",
    label = "",
    variant = "outlined",
    control = "",
    error = false,
    rules = {},
    type = "text",
    helperText = "",
    multiline = false,
    rows = 4,
    disabled = false,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <TextField
                    {...props}
                    {...field}
                    fullWidth={true}
                    type={type}
                    label={label}
                    variant={variant}
                    error={error}
                    helperText={helperText}
                    multiline={multiline}
                    rows={rows}
                    onChange={(value) => {
                        field.onChange(value);
                        props?.onChange && props?.onChange(value, name);
                    }}
                />
            )}
        />
    );
};

TextInput.propTypes = {
    "control": PropTypes.object.isRequired,
    "defaultValue": PropTypes.any,
    "disabled": PropTypes.bool,
    "error": PropTypes.bool,
    "helperText": PropTypes.string,
    "label": PropTypes.string,
    "multiline": PropTypes.bool,
    "name": PropTypes.string.isRequired,
    "onChange": PropTypes.func,
    "rows": PropTypes.number,
    "rules": PropTypes.object,
    "type": PropTypes.string,
    "variant": PropTypes.string
};

export default TextInput;
