import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { MenuItem, Select, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { GridActionsCellItem, GridRowModes, getGridStringOperators } from "@mui/x-data-grid";
import { GrUpgrade } from "react-icons/gr";
import { Link } from "react-router-dom";
import { getDateAndTime } from "../../utils/date";

/**
 * Definition of columns for a table.
 * @param {object} t - The translation function for localization.
 * @param {Array} tenantsID - Array of tenant IDs.
 * @param {object} rowModesModel - Object containing row modes.
 * @param {Function} handleSaveClick - Function to handle Save button click.
 * @param {Function} handleEditClick - Function to handle Edit button click.
 * @param {object} user - Function to have get location data.
 * @param {Function} handleCancelClick - Function to handle cancel button click.
 * @returns {Array} An array of column definitions.
 */
export const columns = (
    t, tenantsID, rowModesModel, handleSaveClick, handleEditClick, user, handleCancelClick
) => [
    {
        "field": "uuid",
        "filterOperators": getGridStringOperators().filter(({ value }) => ["contains"].includes(value)),
        "filterable": true,
        "headerName": t("Bikes.uuid"),
        
        /**
         * Render cell to show hyperlink
         * @param {*} params - Row
         * @returns {Element} - Hyper link
         */
        "renderCell": ({ row }) => {
            return (
                <Link to={`/bikes/${row.uuid}`}>
                    {row.uuid}
                </Link>
            );
        },
        
        /**
         *
         */
        "sortable": true,
        "width": 150
    },
    {
        "field": "short_bike_id",
        "headerName": t("Bikes.short_bike_id"),
        "width": 100
    },
    {
        "field": "bt_connection_status",
        "headerName": t("Bikes.bt_connection_status"),
        /**
         * Custom cell renderer for BT Connection Status column.
         * @param {object} root0 - The cell properties object.
         * @param {boolean} root0.value - The boolean value representing BT connection status.
         * @returns {string} The rendered content based on the connection status.
         */
        "valueGetter": ({ value }) => {
            if (value === true) {
                return "Connected";
            } else if (value === false) {
                return "Disconnected";
            } else {
                return "N/A";
            }
        },
        "width": 200
    },
    {
        "editable": true,
        "field": "tenant_id",
        "headerName": t("Bikes.tenant_id"),
        /**
         * Renders an editable cell with a Select component for selecting a tenant ID.
         * This function is used to render the cell when it's in edit mode within the Material-UI DataGrid.
         * @param {object} params - The parameters object containing information about the cell and grid.
         * @param {string} params.value - The current value of the cell.
         * @returns {Element} The JSX element representing the editable cell with a Select component.
         */
        "renderEditCell": (params) => {
            return (
                <Select
                    fullWidth={true}
                    value={params.row?.changes?.tenant_id === "" ? "" : params.row?.changes?.tenant_id || params.value}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        const updatedRow = { ...params.row };
                        updatedRow.tenant_id = newValue;
                        updatedRow.availableLocations = user.tenants[newValue];
                        updatedRow.changes = {
                            ...updatedRow.changes,
                            "location_id": updatedRow.availableLocations?.[0] || "",
                            "tenant_id": newValue
                        };
                        params.api.updateRows([{ "changes": updatedRow.changes, "uuid": params.id }]);
                    }}
                >
                    <MenuItem value={""}>{t("Dashboard.unAllocate")}</MenuItem>
                    {tenantsID.map((tenant) => (
                        <MenuItem key={tenant} value={tenant}>
                            {tenant}
                        </MenuItem>
                    ))}
                </Select>
            );
        },
        "type": "singleSelect",
        "valueOptions": tenantsID,
        "width": 200
    },
    {
        "editable": true,
        "field": "location_id",
        "headerName": t("Bikes.location_id"),
        /**
         * Renders an editable cell with a Select component for selecting a tenant ID.
         * This function is used to render the cell when it's in edit mode within the Material-UI DataGrid.
         * @param {object} params - The parameters object containing information about the cell and grid.
         * @param {string} params.value - The current value of the cell.
         * @returns {Element} The JSX element representing the editable cell with a Select component.
         */
        "renderEditCell": (params) => {
            const defaultValue =
                params.row?.changes?.tenant_id === "" ? "" : params.row?.changes?.tenant_id || params.row?.tenant_id;
            const availableLocations = user.tenants[defaultValue] || [];

            return (
                <Select
                    value={
                        params.row?.changes?.location_id === "" ? "" : params.row?.changes?.location_id || params.value
                    }
                    fullWidth={true}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        const updatedRow = { ...params.row };
                        updatedRow.location_id = newValue;
                        updatedRow.changes = {
                            ...updatedRow.changes,
                            "location_id": newValue
                        };
                        params.api.updateRows([{ "changes": updatedRow.changes, "uuid": params.id }]);
                    }}
                >
                    <MenuItem value={""}>{t("Dashboard.unAllocate")}</MenuItem>
                    {(availableLocations || []).map((tenant) => (
                        <MenuItem key={tenant} value={tenant}>
                            {tenant}
                        </MenuItem>
                    ))}
                </Select>
            );
        },
        "type": "singleSelect",
        "valueOptions": tenantsID,
        "width": 200
    },
    {
        "field": "firmware",
        "headerName": t("Bikes.firmware"),
        /**
         * Render cell to show target firmware, if different
         * @param {*} params - Row
         * @returns {Element} - Hyper link
         */
        "renderCell": ({ row }) => {
            /**
             * Show upgrade icon if target firmware is set and different from current firmware
             * @returns {Element} - Upgrade icon
             */
            const checkTargetFirmware = () => {
                if (row.target_firmware && row.target_firmware !== row.current_firmware) {
                    return (
                        <Tooltip 
                            arrow={true} 
                            title={t("Bikes.firmware_upgrade_message", { "targetFirmware": row.target_firmware })}
                        >
                            <span>
                                <GrUpgrade size={"1.5rem"} />
                            </span>
                        </Tooltip>
                    );
                }

                return null;
            };

            return (
                <Box display={"flex"} alignItems={"center"} className={"firmware"}>
                    {row.current_firmware} {checkTargetFirmware()}
                </Box>
            );
        },
        
        "width": 150
    },
    {
        "field": "time_stamp",
        "headerName": t("Bikes.time_stamp"),
        "type": "dateTime",
        /**
         * Custom cell renderer for BT Connection Status column.
         * @param {object} root0 - The cell properties object.
         * @param {boolean} root0.value - The boolean value representing BT connection status.
         * @returns {string} The rendered content based on the connection status.
         */
        "valueGetter": ({ value }) => {
            return getDateAndTime(value);
        },
        "width": 200
    },
    {
        "cellClassName": "actions",
        "field": "actions",
        /**
         * Get actions for a row based on its ID.
         * @param  {object} value  - value
         * @returns {Array<Element>} An array of action elements based on the row's edit mode.
         */
        "getActions": (value) => {
            const isInEditMode = rowModesModel[value.id]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        key={`save-action-${value.id}`}
                        icon={<SaveIcon />}
                        label={t("Dashboard.save")}
                        sx={{
                            "color": "primary.main"
                        }}
                        onClick={handleSaveClick(value.id, value.row)}
                    />,
                    <GridActionsCellItem
                        key={`save-action-${value.id}`}
                        icon={<CancelIcon />}
                        label={t("Dashboard.cancel")}
                        className={"textPrimary"}
                        onClick={handleCancelClick(value.id)}
                        color={"inherit"}
                    />
                ];
            }

            return [
                <GridActionsCellItem
                    key={`edit-action-${value.id}`}
                    icon={<EditIcon />}
                    label={t("Dashboard.edit")}
                    className={"textPrimary"}
                    onClick={handleEditClick(value.id)}
                    color={"inherit"}
                />
            ];
        },

        "headerName": t("Bikes.actions"),

        "type": "actions",

        "width": 100
    }
];
