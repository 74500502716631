import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useFirebase } from "../../context/Firebase";

/**
 *
 * @returns {Element} The protected layout component.
 */
const ProtectedLayout = () => {
    const { user, checkTenantPermission, navigateAsPerRole } = useFirebase();
    const location = useLocation();

    const { tenantId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const parts = location.pathname.split("/");
        const currentRoute = parts[parts.length - 1];
        if (user) {
            if (currentRoute.trim() === "") {
                navigateAsPerRole(user);
            } else if (!user.checkUserHaveAccessForRoute(currentRoute, tenantId)) {
                return navigate("/forbidden");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, user, tenantId, navigate, checkTenantPermission]);

    if (user === null) {
        return <Navigate to={"/login"} />;
    } else if (user === undefined) {
        return <Loader />;
    }

    return <Outlet />;
};

export default ProtectedLayout;
