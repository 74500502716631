// TODO:- Add this to remove REACT_APP_ from all env variables
// const prefix = 'REACT_APP_'

/**
 * Gets env variable depending upon environment
 * @param {string} key - Key to be fetched
 * @param {*} defaultValue - Default value if key not found
 * @returns {*} Value against key in environment
 */
const env = (key, defaultValue) => {
    try {
        const envVal =
            (process.env.REACT_APP_ENV === "development" ? process.env[key] : window._env_[key]) || defaultValue;
        if (envVal === undefined || envVal === null) {
            // eslint-disable-next-line no-console
            console.log(`Environment variable ${key} missing with no default value`);
        }

        return envVal;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Not able to get env variable -", key, e);

        return defaultValue;
    }
};

const config = { env };

module.exports = config;
