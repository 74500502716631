import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import drawer from "./reducer/drawer";

import loader from "./reducer/loader";

const reducer = combineReducers({
    drawer,
    loader
});

export default configureStore({
    drawer,
    loader,
    reducer
});
