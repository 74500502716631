/**
 * Custom value getter function.
 * @param {object} value - The value to be processed.
 * @returns {string} The computed value.
 */
export const getDateAndTime = (value) => {
    if (!value?.seconds) {
        return "";
    }
    const date = new Date(value?.seconds * 1000);
    const timezoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date(date.getTime() - timezoneOffset * 60000);

    return localDate;
};

/**
 * Convert date object to date string as per required format
 * @param {Date} date - Date to be converted
 * @param {string} format - Format required
 * @returns {string} - Formatted date string
 */
export const getDateString = (date, format) => {
    if (format === "full") {
        return date.toLocaleDateString("en-UK", {
            "day": "2-digit",
            "hour": "2-digit",
            "minute": "2-digit",
            "month": "2-digit",
            "year": "2-digit"
        });
    } else if (format === "date") {
        return date.toLocaleDateString("en-UK", {
            "day": "2-digit",
            "month": "2-digit",
            "year": "2-digit"
        });
    } else if (format === "month") {
        return date.toLocaleDateString("en-UK", {
            "month": "short",
            "year": "2-digit"
        });
    }

    return "Invalid format passed";
};