import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { FaCarBattery } from "react-icons/fa6";
import { FiUsers } from "react-icons/fi";
import { MdHealthAndSafety, MdOutlinePedalBike } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../../assets/images/energym_logo.png";
import { useFirebase } from "../../../context/Firebase";
import useOutsideClick from "../../../hooks/useOutsideClick";
import Menus from "./Menus";
import "./Sidebar.scss";

/**
 * Sidebar component for your app.
 * @param {object} props - Component props.
 * @param {boolean} props.open - Indicates whether the sidebar is open.
 * @param {Function} props.handleDrawerClose - Callback to handle the sidebar closing.
 * @param {boolean} props.isTablet - Indicates whether the sidebar is being displayed on a tablet.
 * @param {Function} props.t - Translation function
 * @returns {Element} - Sidebar Element
 */
const Sidebar = ({ open, isTablet, handleDrawerClose, t }) => {
    const [sideBarItems, setSideBarItems] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState({});
    const [visibleBackBtn, setVisibleBackBtn] = useState(true);

    const impactRef = useRef();
    const { tenantId } = useParams();
    const navigate = useNavigate();
    const { user } = useFirebase();
    useOutsideClick(impactRef, () => {
        if (isTablet) {
            handleDrawerClose(false);
        }
    });

    /**
     * Clears the currently selected tenant and navigates to the dashboard.
     */
    const handleClearSelectedTenant = () => {
        setSelectedTenant({});
        const route = user?.isSuperAdmin() ? "/bikes" : "/gyms";
        navigate(route);
    };

    useEffect(() => {
        let menus = [
            {
                "Icon": <FiUsers size={"1.25rem"} />,
                "key": "gym",
                "name": t("Header.titles.gyms"),
                "route": "/gyms",
                "subItems": user?.tenantList || []
            }
        ];

        if ((user?.tenantList || []).length === 1 && !user.isSuperAdmin()) {
            setVisibleBackBtn(false);
        }

        if (user.isSuperAdmin()) {
            menus.push({
                "Icon": <MdOutlinePedalBike size={"1.25rem"} />,
                "active": true,
                "key": "bikes",
                "name": t("Header.titles.bikes"),
                "route": "/bikes"
            });
        }

        const findTenants = (user?.tenantList || []).find(({ id }) => id === tenantId);

        if (findTenants) {
            const tenantMenu = [
                {
                    "Icon": <MdHealthAndSafety size={"1.25rem"} />,
                    "active": true,
                    "key": "bike-health",
                    "name": t("Header.titles.bike-health"),
                    "route": `/gym/${findTenants.id}/bike-health`
                },
                {
                    "Icon": <FaCarBattery size={"1.25rem"} />,
                    "key": "battery-dashboard",
                    "name": t("Header.titles.battery-dashboard"),
                    "route": `/gym/${findTenants.id}/battery-dashboard`
                }
            ];
            menus = tenantMenu.filter((menu) => {
                return findTenants?.accessScreens?.includes(menu.key);
            });
            setSelectedTenant(findTenants);
        }
        setSideBarItems(menus);

        return () => {
            setSelectedTenant({});
        };
    }, [user, user?.tenantList, tenantId, t, selectedTenant?.accessScreens]);

    return (
        <Drawer ref={impactRef} className={"side-bar"} variant={"persistent"} anchor={"left"} open={open}>
            <Box className={"menu"}>
                {selectedTenant?.name && (
                    <Box className={"side-header"}>
                        {visibleBackBtn && (
                            <IconButton size={"small"} onClick={handleClearSelectedTenant}>
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                        <Box> {selectedTenant?.name} </Box>
                    </Box>
                )}

                <Menus items={sideBarItems} handleDrawerClose={handleDrawerClose} isTablet={isTablet} />
            </Box>
            <Box className={"logo-container"}>
                <img src={Logo} alt={"logo"} />
            </Box>
        </Drawer>
    );
};

// Define PropTypes validation for the component
Sidebar.propTypes = {
    "handleDrawerClose": PropTypes.func.isRequired,
    "isTablet": PropTypes.bool.isRequired,
    "open": PropTypes.bool.isRequired,
    "t": PropTypes.func.isRequired
};

export default withTranslation()(Sidebar);
