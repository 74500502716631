import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Logo from "../../assets/images/energym_logo.png";
import TextInput from "../../components/Form/TextInput";
import { useFirebase } from "../../context/Firebase";
import "./Login.scss";
import { loginSchema } from "./schema";

/**
 * Storage Location Detail component for Storage Location Map
 * @returns {Element} - return tool tip for Storage Location Map
 */
const Login = () => {
    const { t } = useTranslation();
    const loading = useSelector((state) => state.loader.loading);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const {
        control,
        handleSubmit,
        "formState": { errors }
    } = useForm({
        "resolver": yupResolver(loginSchema(!isResetPassword, t))
    });

    const firebase = useFirebase();

    /**
     * Logs in a user with the provided data.
     * This function is called when a user attempts to log in.
     * @param {object} data - The login data provided by the user.
     */
    const onSubmit = async (data) => {
        await firebase.loginUser(data, isResetPassword, t);
    };

    /**
     * Toggle the reset password functionality.
     */
    const toggleResetPassword = () => {
        setIsResetPassword((preState) => !preState);
    };

    return (
        <Box className={"login-form-container"}>
            <Box className={"logo"}>
                <img src={Logo} alt={"Energym"} />
            </Box>
            <Paper elevation={3} className={"login-form"}>
                <Typography variant={"h5"} className={"login-title"}>
                    {isResetPassword ? t("Login.resetPassword") : t("Login.title")}
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                        name={"email"}
                        type={"email"}
                        defaultValue={""}
                        label={t("Login.email") + "*"}
                        variant={"outlined"}
                        control={control}
                        rules={{}}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email?.message?.toString() : ""}
                    />

                    {!isResetPassword && (
                        <TextInput
                            name={"password"}
                            type={"password"}
                            defaultValue={""}
                            label={t("Login.password") + "*"}
                            variant={"outlined"}
                            control={control}
                            rules={{}}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password?.message?.toString() : ""}
                        />
                    )}

                    <LoadingButton
                        loading={loading}
                        variant={"contained"}
                        color={"primary"}
                        type={"submit"}
                        fullWidth={true}
                        loadingPosition={"start"}
                    >
                        {isResetPassword ? t("Login.reset") : t("Login.title")}
                    </LoadingButton>
                </form>
                <Box className={"login-form__reset_password"} onClick={toggleResetPassword}>
                    {isResetPassword ? t("Login.back") : t("Login.resetPassword")}
                </Box>
            </Paper>
        </Box>
    );
};

export default Login;
