import { Box, FormControl, Grid, MenuItem, TextField } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { GiElectricalSocket } from "react-icons/gi";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ValueCard from "../../components/ValueCard/ValueCard";
import { useFirebase } from "../../context/Firebase";
import { getInstallationStats } from "../../services/VRM";
import { endLoading, startLoading } from "../../store/reducer/loader";
import { getDateString } from "../../utils/date";
import "./BatteryDashboard.scss";

/**
 * Battery Dashboard Component to display battery related info
 * @param {*} props - Props
 * @returns {Element} - Battery Dashboard Component
 */
const BatteryDashboard = ({ t }) => {
    const filters = [
        { "label": "Last 3 hours", "value": 3 },
        { "label": "Last 6 hours", "value": 6 },
        { "label": "Last 12 hours", "value": 12 }, 
        { "label": "Last 24 hours", "value": 24 }, 
        { "label": "Last 2 days", "value": 48 }, 
        { "label": "Last 7 days", "value": 168 }, 
        { "label": "Last 30 days", "value": 720 }, 
        { "label": "Last 3 months", "value": 2160 }, 
        { "label": "Last 6 months", "value": 4320 }, 
        { "label": "Last 1 year", "value": 8640 }
    ];
    const { tenantId } = useParams();
    const { user } = useFirebase();
    const dispatch = useDispatch();

    const [installationStats, setInstallationStats] = useState();
    const [selectedFilter, setSelectedFilter] = useState(filters[0]);
    const [chartSeries, setChartSeries] = useState([]);
    const [interval, setInterval] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if (tenantId && user) {
            dispatch(startLoading());
            getInstallationStats(tenantId, user, selectedFilter.value).then((response) => {
                if (response.success) {
                    setInterval(response.interval);
                    setInstallationStats(response.totals);
                    const x = [
                        response.records.total_consumption.map(consumption => new Date(consumption[0])),
                        response.records.total_consumption.map(consumption => consumption[1])
                    ];
                    setChartSeries(x);
                    
                }
            }).catch((error) => {
                setError(error);
            }).finally(() => {
                dispatch(endLoading());
            });
        }
    }, [tenantId, user, selectedFilter, dispatch]);

    /**
     * Formats date axis
     * @param {Date} date - Date
     * @returns {string} - Formatted date string
     */
    const dateFormatter = (date) => {
        let dateString = date;
        switch (interval) {
            case "15mins":
            case "hours":
                dateString = getDateString(date, "full");
                break;
            case "days":
            case "weeks":
                dateString = getDateString(date, "date");
                break;
            case "months":
                dateString = getDateString(date, "month");
                break;
        }

        return dateString;
    };

    /**
     * Appends unit at the end of value
     * @param {number} value - Value to be formatted
     * @returns {string} Formatted value
     */
    const valueFormatter = (value) => {
        return `${value.toFixed(2)} kWh`;
    };

    return (
        <Box className={"battery-dashboard"}>
            <Box alignItems={"flex-end"} display={"flex"} flexDirection={"column"}>
                <FormControl sx={{ "alignSelf": "end", "py": 2, "width": 400 }}>
                    <TextField
                        fullWidth={true}
                        value={selectedFilter.value}
                        select={true}
                        label={t("BatteryDashboard.filter")}
                        onChange={(event) => {
                            setSelectedFilter(filters.find(filter => filter.value === event.target.value));
                        }}
                    >
                        {filters.map((filter) => (
                            <MenuItem key={filter.value} value={filter.value}>
                                {filter.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Box>
            {chartSeries.length ? (
                    <BarChart
                        xAxis={[{
                            "data": chartSeries[0],
                            "scaleType": "band", 
                            "valueFormatter": dateFormatter 
                        }]}
                        series={[{
                            "color": "#1da538", 
                            "data": chartSeries[1],
                            "valueFormatter": valueFormatter
                        }]}
                        height={500}
                    />
                ) : null
            }
            {installationStats ? (
                    <Grid container={true} spacing={2} alignItems={"stretch"}>
                        <ValueCard
                            icon={GoArrowLeft}
                            title={t("BatteryDashboard.to_grid")}
                            value={installationStats?.grid_history_to}
                            unit={"kWh"}
                        />
                        <ValueCard
                            icon={GoArrowRight}
                            title={t("BatteryDashboard.from_grid")}
                            value={installationStats?.grid_history_from}
                            unit={"kWh"}
                        />
                        <ValueCard
                            icon={GiElectricalSocket}
                            title={t("BatteryDashboard.consumption")}
                            value={installationStats?.total_consumption}
                            unit={"kWh"}
                        />
                    </Grid>
                ) : null
            }
            {error ? (
                <Box className={"error"}>
                    <span>{error}</span>
                </Box>
            ) : null}
        </Box>
    );
};

BatteryDashboard.propTypes = {
    "t": PropTypes.func.isRequired
};

export default withTranslation()(BatteryDashboard);