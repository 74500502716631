import Tenant from "./Tenant";

export default class User {
    email;
    fullName;
    password;
    roles = ""; // viewer, admin, super_admin
    tenants = {};
    selectedTenant;
    navigate;
    tenantList = [];
    idToken = "";
    refreshToken = "";
    profilePhoto = "";
    /**
     * Model for user
     * @param {object} input  - The input object containing user data.
     */
    constructor(input) {
        this.email = input?.email || "";
        this.fullName = input?.fullName || "";
        this.password = input?.password || "";
        this.tenants = input?.tenants || {};
        this.roles = input?.roles || ""; // Provide a default value
        this.profilePhoto = input?.profilePhoto || "";
        this.navigate = input?.navigate || {};
        this.idToken = input?.idToken || {};
        this.refreshToken = input?.refreshToken || {};
        this.tenantList = input?.tenantList ? input?.tenantList.map((tenant) => new Tenant(tenant)) : [];
    }

    /**
     * Check if user is super admin
     * @returns {boolean} true if super admin
     */
    isSuperAdmin() {
        return this.roles === "super_admin";
    }

    /**
     * Check if user does not have any role
     * @returns {boolean} true if does not have any role
     */
    isUserNotHaveAnyRole() {
        return !this.roles;
    }

    /**
     * Get a list of all tenants.
     * @returns {string[]} - An array of tenant names.
     */
    getAllTenantsName() {
        return Object.keys(this.tenants);
    }

    /**
     * Check if a user has access to a specific route based on their role.
     * @param {string} currentRoute - The current route to check access for.
     * @param {string}tenantId -- The Tenant ID
     * @returns {boolean} - `true` if the user has access, `false` otherwise.
     */

    /**
     * Check if the user has access to a specific route for the given tenant.
     * @param {string} currentRoute - The route to check access for.
     * @param {string | null} tenantId - The ID of the tenant (default: null).
     * @returns {boolean} - `true` if the user has access, `false` otherwise.
     */
    checkUserHaveAccessForRoute(currentRoute, tenantId = null) {
        if (tenantId === null) { 
            return currentRoute === "gyms" || this.isSuperAdmin();
        }
        const tenantRoutes = this.tenantList.find(({ id }) => id === tenantId);

        if (!tenantRoutes) {
            return false;
        }

        if (this.isSuperAdmin()) {
            return true;
        }

        return tenantRoutes?.accessScreens[0]?.includes(currentRoute);
    }

    /**
     * Gets site id from the matching tenant
     * @param {string} tenantId - Tenant ID
     * @returns {string} Site ID
     */
    getSiteId(tenantId) {
        const selectedTenant = this.tenantList.find((tenant) => tenant.id === tenantId);

        return { "siteId": selectedTenant?.vrm?.site_id, "token": `Token ${selectedTenant?.vrm?.token}` };
    };
}
