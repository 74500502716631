import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { FirebaseProvider } from "../../context/Firebase";
import { MQTTProvider } from "../../context/MQTT";
import BatteryDashboard from "../../pages/BatteryDashboard/BatteryDashboard";
import BikeHealth from "../../pages/BikeHealth/BikeHealth";
import Bikes from "../../pages/Bikes/Bikes";
import Forbidden from "../../pages/Forbidden/Forbidden";
import Login from "../../pages/Login/Login";
import Logs from "../../pages/Logs/Logs";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";

/**
 *  AllRoutes component handles routing within your application.
 *  @returns {Element} The JSX element representing the routes.
 */
const AllRoutes = () => {
    return (
        <BrowserRouter>
            <FirebaseProvider>
                <Routes>
                    <Route path={"/login"} element={<Login />} />

                    <Route path={"/"} element={<ProtectedRoute />}>
                        <Route element={<Layout />}>
                            <Route path={"gyms"} element={<></>} />
                            <Route path={"bikes"} element={<Bikes />} />
                            <Route path={"bikes/:uuid"} element={<Logs />} />
                            <Route path={"gym/:tenantId/"} element={
                                <MQTTProvider><Outlet /></MQTTProvider>
                            }
                            >
                                <Route path={"bike-health"} element={<BikeHealth />} />
                                <Route path={"battery-dashboard"} element={<BatteryDashboard />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path={"/forbidden"} element={<Forbidden />} />
                    <Route path={"*"} element={<Navigate to={"/"} />} />
                </Routes>
            </FirebaseProvider>
        </BrowserRouter>
    );
};

export default AllRoutes;
