import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Menu,
    MenuItem,
    TextField
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridRowModes } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCaretDown } from "react-icons/fa";
import { toast } from "react-toastify";
import { useFirebase } from "../../context/Firebase";
import { debounce } from "../../utils/debounce";
import "./Bikes.scss";
import { columns } from "./columns";

/**
 * Bikes component.
 * @returns {Element} The JSX element representing the Bikes.
 */
const Bikes = () => {
    const { 
        getBikes, user, editLocationOfTenant, changeFirmwareVersion, getAllFirmwareVersions, firmwareVersions
    } = useFirebase();
    const { t } = useTranslation();

    const [rowModesModel, setRowModesModel] = useState({});
    const [tenantsID, setTenantsID] = useState([]);
    const [selectedUUID, setSelectedUUID] = useState([]);
    const [changeTenantModal, setChangeTenantModal] = useState(false);
    const [updateToVersion, setUpdateToVersion] = useState();

    const [tableFilters, setTableFilters] = useState({
        "currentPage": 0,
        "data": [],
        "filters": [],
        "pageSizeData": 10,
        "refresh": false,
        "searchQuery": "",
        "sortedColumn": "uuid",
        "sortedOrder": "desc",
        "totalDocs": null
    });
    const [actionsMenu, setActionsMenu] = useState(null);

    useEffect(() => {
        (async () => {
            const bikes = await getBikes({
                "currentPage": tableFilters.currentPage,
                "filters": tableFilters.filters,
                "lastUUID": tableFilters.data.length ? tableFilters.data[tableFilters.data.length - 1].uuid : null,
                "pageSizeData": tableFilters.pageSizeData,
                "searchQuery": tableFilters.searchQuery,
                "sortedColumn": tableFilters.sortedColumn,
                "sortedOrder": tableFilters.sortedOrder,
                "totalDocs": tableFilters.totalDocs
            });
            const bikesRequiredData = (bikes?.bikeData || []).map((bike) => {
                return {
                    "bt_connection_status": bike.logs?.bt_connection_status,
                    "current_firmware": bike.bike?.firmware?.current_firmware || "N/A",
                    "docId": bike.bike?.docId,
                    "id": bike.bike?.uuid,
                    "location_id": bike.bike?.location_id || "N/A",
                    "short_bike_id": bike.bike?.short_bike_id,
                    "target_firmware": bike.bike?.firmware?.target_firmware,
                    "tenant_id": bike.bike?.tenant_id || "N/A",
                    "time_stamp": bike.logs?.time_stamp,
                    "uuid": bike.bike?.uuid
                };
            });
            setTenantsID([...new Set(Object.keys(user.tenants))]);
            handleChangeFilters({ "data": bikesRequiredData, "totalDocs": bikes?.totalDocs || 0 });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        tableFilters.currentPage,
        tableFilters.pageSizeData,
        tableFilters.sortedColumn,
        tableFilters.sortedOrder,
        tableFilters.refresh,
        tableFilters.filters
    ]);

    useEffect(() => {
        getAllFirmwareVersions();
    }, [getAllFirmwareVersions]);

    /**
     * Handles the change in filters.
     * @param {object} appliedFilter - The value to set for the filter.
     */
    const handleChangeFilters = (appliedFilter) => {
        setTableFilters((prevState) => ({
            ...prevState,
            ...appliedFilter
        }));
    };

    /**
     * Handle to refresh data
     */
    const handleRefetch = () => {
        setTableFilters((prevState) => ({
            ...prevState,
            "refresh": !prevState.refresh
        }));
    };

    /**
     * Handle change query
     * @param {object}query - query which contain value entered by user
     */
    const handleChangeQuery = (query) => {
        debounce(() => {
            if (query.items.length) {
                const filteredItems = query.items.filter(({ value }) => value && value?.trim() !== "");

                const filters = filteredItems.map(({ field, value }) => {
                    return {
                        "searchColumn": field,
                        "searchQuery": value,
                        "searchType": field === "time_stamp" ? ">=" : "contains"
                    };
                });
                handleChangeFilters({ "currentPage": 0, "filters": filters });
            }
        }, 1000)();
    };

    /**
     * handleRowModesModelChange
     * @param  {object}newRowModesModel -newRowModesModel
     */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /**
     * Handles the click event for saving a row.
     * @param {string} id - The ID of the row to rowModesModel.
     * @param {object} row - row
     * @returns {object} The update RowModesModel
     */
    const handleSaveClick = (id, row) => () => {
        const { changes, docId } = row;
        if (
            (changes?.location_id === "" || changes?.location_id) &&
            (changes?.tenant_id === "" || changes?.tenant_id) &&
            changes?.location_id !== "N/A" &&
            changes?.tenant_id !== "N/A"
        ) {
            editLocationOfTenant({
                "docId": docId,
                "locationID": changes.location_id,
                t,
                "tenantID": changes.tenant_id
            });
            handleRefetch();
        } else {
            toast.error("Please select both value");
        }
        setRowModesModel({ ...rowModesModel, [id]: { "mode": GridRowModes.View } });
    };

    /**
     * Handles the click event for editing a row.
     * @param {string} id - The ID of the row to edit.
     * @returns {object} The update RowModesModel
     */
    const handleEditClick = (id) => () => {
        setRowModesModel({ [id]: { "mode": GridRowModes.Edit } });
    };

    /**
     * Handles the cancel click event.
     * @param {string} id - The ID of the row to cancel.
     * @returns {Function} A callback function.
     */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { "ignoreModifications": true, "mode": GridRowModes.View }
        });
    };

    /**
     * Show actions if rows selected
     * @param {Array} newRowSelectionModel - Selected rows
     */
    const onRowSelection = (newRowSelectionModel) => {
        setSelectedUUID(newRowSelectionModel);
    };

    /**
     * Close actions menu
     */
    const handleClose = () => {
        setActionsMenu(null);
    };

    /**
     *
     */
    const handleFirmwareDialogClose = () => {
        setUpdateToVersion(null);
        setChangeTenantModal(false);
    };

    return (
        <Box className={"page-container bikes"}>
            <Box sx={{ "display": "flex", "flexDirection": "row-reverse", "my": 1 }}>
                <Button 
                    variant={"outlined"}
                    aria-controls={"avatar-menu"}
                    aria-haspopup={"true"}
                    onClick={(event) => {
                        setActionsMenu(event.currentTarget);
                    }}
                    disabled={!selectedUUID.length}
                    endIcon={<FaCaretDown />}
                >
                    {t("Bikes.actions")}
                </Button>
                <Menu
                    id={"avatar-menu"}
                    anchorEl={actionsMenu}
                    open={Boolean(actionsMenu)}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        "horizontal": "right",
                        "vertical": "bottom"
                    }}
                    transformOrigin={{
                        "horizontal": "right",
                        "vertical": "top"
                    }}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {
                        setChangeTenantModal(true);
                        handleClose();
                    }}
                    > {t("Bikes.changeFirmware")}
                    </MenuItem>
                </Menu>
            </Box>
            <DataGrid
                rows={tableFilters.data || []}
                columns={columns(
                    t,
                    tenantsID,
                    rowModesModel,
                    handleSaveClick,
                    handleEditClick,
                    user,
                    handleCancelClick
                )}
                className={"emptyDataGrid"}
                initialState={{
                    "pagination": { "paginationModel": { "pageSize": tableFilters.pageSizeData } },
                    "sorting": {
                        "sortModel": [
                            {
                                "field": tableFilters.sortedColumn,
                                "sort": tableFilters.sortedOrder
                            }
                        ]
                    }
                }}
                paginationModel={{
                    "page": tableFilters.currentPage,
                    "pageSize": tableFilters.pageSizeData
                }}
                rowCount={tableFilters.totalDocs || 0}
                onPaginationModelChange={(newPage) => {
                    handleChangeFilters({ "currentPage": newPage.page, "pageSizeData": newPage.pageSize });
                }}
                sortingMode={"server"}
                filterMode={"server"}
                onSortModelChange={(newSortModel) => {
                    handleChangeFilters({
                        "currentPage": 0,
                        "sortedColumn": newSortModel?.[0]?.field,
                        "sortedOrder": newSortModel?.[0]?.sort
                    });
                }}
                onRowSelectionModelChange={onRowSelection}
                checkboxSelection={true}
                getRowId={(row) => row.uuid}
                pageSizeOptions={[5, 10, 25]}
                paginationMode={"server"}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onFilterModelChange={handleChangeQuery}
                sortingOrder={["asc", "desc"]}
                slotProps={{
                    "toolbar": { setRowModesModel }
                }}
            />

            <Dialog
                open={changeTenantModal}
                onClose={handleFirmwareDialogClose}
                sx={{ "p": 2 }}
            >
                <DialogTitle>{t("Bikes.changeFirmware")}</DialogTitle>
                <DialogContent sx={{ "minWidth": 400 }}>
                    <FormControl fullWidth={true} sx={{ "py": 2 }}>
                        <TextField
                            fullWidth={true}
                            value={updateToVersion ? updateToVersion : ""}
                            labelId={"firmware-select-label"}
                            select={true}
                            label={t("Bikes.selectFirmware")}
                            onChange={(event) => {
                                setUpdateToVersion(event.target.value);
                            }}
                        >
                            {firmwareVersions.map((version) => (
                                <MenuItem key={version} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>

                    <Box sx={{ "display": "flex", "gap": 2, "justifyContent": "flex-end", "mt": 1 }}>
                        <Button color={"secondary"} onClick={handleFirmwareDialogClose}>
                            {t("Bikes.cancel")}
                        </Button>
                        <Button variant={"outlined"} onClick={async () => {
                            const success = await changeFirmwareVersion(
                                selectedUUID, updateToVersion, tableFilters.data
                            );
                            if (success) {
                                handleFirmwareDialogClose();
                                handleRefetch();
                            }
                        }}
                        >
                            {t("Bikes.save")}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

Bikes.propTypes = {};

export default Bikes;
