import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
    "initialState": {
        "loading": false
    },
    "name": "load",
    "reducers": {
        /**
         * customLoading abc
         * @param {object}state - The current state.
         * @param {object}action - The action object with a 'payload'.
         */
        "customLoading": (state, action) => {
            state.loading = action.payload;
        },

        /**
         *
         * @param {object}state - The current state.
         */
        "endLoading": (state) => {
            state.loading = false;
        },

        /**
         *
         * @param {object}state - The current state.
         */
        "startLoading": (state) => {
            state.loading = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { startLoading, endLoading, customLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
