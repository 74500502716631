/**
 * Saves a value to the local storage.
 * @param {string} key - The key to use for storing the value.
 * @param {string} value - The value to store.
 */
export const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
};

/**
 * Retrieves a value from the local storage.
 * @param {string} key - The key used to store the value.
 * @param {boolean}isJson - - The isJson to get json data .
 * @returns {string|null} - The retrieved value or null if not found.
 */
export const getFromLocalStorage = (key, isJson = false) => {
    try {
        return isJson ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
    } catch {
        return null;
    }
};

/**
 * Removes a value from the local storage.
 * @param {string} key - The key used to store the value.
 */
export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
};

/**
 * Saves a JSON object to the local storage.
 * @param {string} key - The key to use for storing the JSON object.
 * @param {object} value - The JSON object to store.
 * @returns {void}
 */
export const saveJsonToLocalStorage = (key, value) => {
    const serializedValue = typeof value === "string" ? value : JSON.stringify(value);
    saveToLocalStorage(key, serializedValue);
};

/**
 * Store data in sessionStorage, converting non-string values to JSON strings if needed.
 * @param {string} key - The key to store the data.
 * @param {any} value - The data to be stored.
 */
export const saveToSessionStorage = (key, value) => {
    const serializedValue = typeof value === "string" ? value : JSON.stringify(value);
    sessionStorage.setItem(key, serializedValue);
};

/**
 * Retrieves a value from the session storage.
 * @param {string} key - The key used to store the value.
 * @param {boolean}isJson - - The isJson to get json data .
 * @returns {string|null} - The retrieved value or null if not found.
 */
export const getFromSessionStorage = (key, isJson = false) => {
    try {
        return isJson ? JSON.parse(sessionStorage.getItem(key)) : sessionStorage.getItem(key);
    } catch {
        return null;
    }
};

/**
 * Removes a value from the session storage.
 * @param {string} key - The key used to store the value.
 */
export const removeFromSessionStorage = (key) => {
    sessionStorage.removeItem(key);
};

/**
 * clear all storage
 */
export const removeFromStorage = () => {
    // Clear data from session storage
    sessionStorage.clear();

    // Clear data from local storage
    localStorage.clear();
};
