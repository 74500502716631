import { Box, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BikeTile from "../../components/BikeTile/BikeTile";
import { useFirebase } from "../../context/Firebase";
import { useMQTT } from "../../context/MQTT";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    "PaperProps": {
        "style": {
            "maxHeight": ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            "width": 250
        }
    }
};

/**
 * Get styles based on the provided parameters.
 * @param {string} location - The location to check.
 * @param {string} locations - The location's name to compare with 'locations name'.
 * @param {object} theme - The theme object.
 * @returns {object} The styles object with 'fontWeight' property.
 */
const getStyles = (location, locations, theme) => {
    return {
        "fontWeight":
            locations?.indexOf(location) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
};

/**
 * BikeHealth component
 * @returns {Element} - The root element of the Energym application.
 */
const BikeHealth = () => {
    const { getBikes, user } = useFirebase();
    const { bikeMQTTData } = useMQTT();
    const theme = useTheme();
    const { tenantId } = useParams();

    const [tableFilters, setTableFilters] = useState({
        "currentPage": 0,
        "data": [],
        "filters": [],
        "refresh": false,
        "searchQuery": "",
        "sortedColumn": "uuid",
        "sortedOrder": "desc",
        "totalDocs": null
    });
    const [locations, setLocations] = useState(null);
    const [allLocations, setAllLocations] = useState([]);

    useEffect(() => {
        if (locations && tenantId) {
            (async () => {
                const bikes = await getBikes({
                    "currentPage": tableFilters.currentPage,
                    "filters": [
                        {
                            "searchColumn": "location_id",
                            "searchQuery": locations,
                            "searchType": "in"
                        },
                        {
                            "searchColumn": "tenant_id",
                            "searchQuery": tenantId,
                            "searchType": "=="
                        }
                    ],
                    "lastUUID": tableFilters.data.length ? tableFilters.data[tableFilters.data.length - 1].uuid : null,
                    "pageSizeData": tableFilters.pageSizeData,
                    "searchQuery": tableFilters.searchQuery,
                    "sortedColumn": tableFilters.sortedColumn,
                    "sortedOrder": tableFilters.sortedOrder,
                    "tenantsFilter": tenantId,
                    "totalDocs": tableFilters.totalDocs
                });

                const bikesRequiredData = (bikes?.bikeData || []).map((bike) => {
                    return {
                        "bt_connection_status": bike.logs?.bt_connection_status,
                        "docId": bike.bike?.docId,
                        "id": bike.bike?.uuid,
                        "location_id": bike.bike?.location_id || "N/A",
                        "short_bike_id": bike.bike?.short_bike_id,
                        "tenant_id": bike.bike?.tenant_id || "N/A",
                        "time_stamp": bike.logs?.time_stamp,
                        "uuid": bike.bike?.uuid,
                        "version": bike.logs?.version
                    };
                });

                handleChangeFilters({ "data": bikesRequiredData });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, tenantId]);

    useEffect(() => {
        if (tenantId) {
            setAllLocations(user.tenants[tenantId]);
            setLocations(user.tenants[tenantId]);
        }
    }, [tenantId, user.tenants]);

    /**
     * Handles the change in filters.
     * @param {object} appliedFilter - The value to set for the filter.
     */
    const handleChangeFilters = (appliedFilter) => {
        setTableFilters((prevState) => ({
            ...prevState,
            ...appliedFilter
        }));
    };

    /**
     * Handle a change in the location input field.
     * @param {object} event - The change event object.
     */
    const handleChangeLocation = (event) => {
        const {
            "target": { value }
        } = event;

        if (value.length === 0) {
            return;
        }
        setLocations(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <Box className={"bike-health"}>
            <FormControl sx={{ "m": 1, "width": 300 }}>
                <InputLabel id={"demo-multiple-chip-label"}>Location</InputLabel>
                <Select
                    labelId={"demo-multiple-chip-label"}
                    id={"demo-multiple-chip"}
                    multiple={true}
                    value={locations || []}
                    onChange={handleChangeLocation}
                    input={<OutlinedInput id={"select-multiple-chip"} label={"Chip"} />}
                    renderValue={(selected) => (
                        <Box sx={{ "display": "flex", "flexWrap": "wrap", "gap": 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {allLocations.map((location) => (
                        <MenuItem key={location} value={location} style={getStyles(location, locations, theme)}>
                            {location}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container={true} spacing={2} alignItems={"stretch"}>
                {tableFilters.data.map((bike) => (
                    <Grid item={true} xs={12} sm={6} md={4} lg={3} key={bike.id}>
                        <BikeTile
                            key={bike?.id}
                            reGen={bike?.short_bike_id || "N/A"}
                            uuid={bike?.id || "N/A"}
                            bikeStatus={bike?.bt_connection_status !== undefined ? 
                                    bike.bt_connection_status : false}
                            version={bike?.version || "N/A"}
                            bikeMQTTData={bikeMQTTData[bike.id]}
                            isSuperAdmin={user.isSuperAdmin()}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default BikeHealth;
