import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFirebase } from "../../context/Firebase";
import { debounce } from "../../utils/debounce";
import { columns } from "./columns";

/**
 * Logs Component - Displays logs information.
 * This component renders logs information, allowing users to view and interact with logs data.
 * @returns {Element} The rendered Logs component.
 */
const Logs = () => {
    const { fetchLogsByUuid } = useFirebase();
    const { t } = useTranslation();
    const { uuid } = useParams();
    const [tableFilters, setTableFilters] = useState({
        "currentPage": 0,
        "data": [],
        "filters": [],
        "lastUUID": "",
        "pageSizeData": 10,
        "refresh": false,
        "sortedColumn": "time_stamp",
        "sortedOrder": "desc",
        "totalDocs": null
    });
    useEffect(() => {
        if (uuid) {
            (async () => {
                const logs = await fetchLogsByUuid({
                    "bikeUuid": uuid,
                    ...tableFilters,
                    "lastUUID": tableFilters.data?.length
                        ? tableFilters.data[tableFilters.data?.length - 1].docId
                        : null
                });

                handleChangeFilters({
                    "data": logs.logsData,
                    "totalDocs": logs.totalDocs || 0
                });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        uuid,
        fetchLogsByUuid,
        tableFilters.currentPage,
        tableFilters.pageSizeData,
        tableFilters.refresh,
        tableFilters.sortedColumn,
        tableFilters.sortedOrder,
        tableFilters.filters
    ]);

    /**
     * Handles the change in filters.
     * @param {object} appliedFilter - The object for the filter.
     */
    const handleChangeFilters = (appliedFilter) => {
        setTableFilters((prevState) => ({
            ...prevState,
            ...appliedFilter
        }));
    };

    /**
     * Handle change query
     * @param {object}query - query which contain value entered by user
     */
    const handleChangeQuery = (query) => {
        debounce(() => {
            if (query.items.length) {
                const filteredItems = query.items.filter(({ value }) => value && value?.trim() !== "");

                const filters = filteredItems.map(({ field, value }) => {
                    return {
                        "searchColumn": field,
                        "searchQuery": value,
                        "searchType": field === "time_stamp" ? ">=" : "contains"
                    };
                });
                handleChangeFilters({ "currentPage": 0, "filters": filters });
            }
        }, 1000)();
    };

    return (
        <Box className={"page-container"}>
            <DataGrid
                rows={tableFilters.data || []}
                columns={columns(t)}
                className={"emptyDataGrid"}
                initialState={{
                    "pagination": { "paginationModel": { "pageSize": tableFilters.pageSizeData } },
                    "sorting": {
                        "sortModel": [
                            {
                                "field": tableFilters.sortedColumn,
                                "sort": tableFilters.sortedOrder
                            }
                        ]
                    }
                }}
                paginationModel={{
                    "page": tableFilters.currentPage,
                    "pageSize": tableFilters.pageSizeData
                }}
                rowCount={tableFilters.totalDocs || 0}
                onPaginationModelChange={(newPage) => {
                    handleChangeFilters({ "currentPage": newPage.page, "pageSizeData": newPage.pageSize });
                }}
                sortingMode={"server"}
                filterMode={"server"}
                onSortModelChange={(newSortModel) => {
                    handleChangeFilters({
                        "currentPage": 0,
                        "sortedColumn": newSortModel?.[0]?.field,
                        "sortedOrder": newSortModel?.[0]?.sort
                    });
                }}
                getRowId={(row) => row.docId}
                pageSizeOptions={[5, 10, 25]}
                paginationMode={"server"}
                onFilterModelChange={handleChangeQuery}
                sortingOrder={["asc", "desc"]}
            />
        </Box>
    );
};

export default Logs;
