import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { closeDrawer, openDrawer } from "../../store/reducer/drawer";
import Header from "./Header/Header";
import "./Layout.scss";
import Sidebar from "./Sidebar/Sidebar";

/**
 * Layout component for your app.
 * @returns {Element} - Layout Element
 */
const Layout = () => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.drawer.open);

    const isTablet = useMediaQuery("(max-width:1024px)");

    /**
     * Closes the sidebar.
     */
    const handleDrawerClose = () => {
        dispatch(closeDrawer());
    };

    useEffect(() => {
        if (isTablet) {
            dispatch(closeDrawer());
        } else {
            dispatch(openDrawer());
        }
    }, [dispatch, isTablet]);

    return (
        <div className={"layout-root"}>
            <CssBaseline />
            <Sidebar isTablet={isTablet} open={open} handleDrawerClose={handleDrawerClose} />
            <main className={`content ${open && !isTablet ? "content-shifted" : ""}`}>
                <Header />
                <div className={"main-container"}>
                    <Outlet />
                </div>
            </main>
        </div>
    );
};

export default Layout;
