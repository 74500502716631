import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./Forbidden.scss";

/**
 * Forbidden component.
 * @returns {Element} The JSX element representing the Forbidden.
 */
const Forbidden = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box className={"forbidden"}>
            <Box>
                <Typography variant={"h3"}> 403 </Typography>
                <Typography variant={"h4"}> {t("Forbidden.title")} </Typography>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        navigate(-2);
                    }}
                >
                    {t("Login.back")}
                </Button>
            </Box>
        </Box>
    );
};

export default Forbidden;
