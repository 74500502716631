import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Menus.scss";
/**
 * Display a dynamic menu based on the provided items.
 * @param {object} props - Component props.
 * @param {object[]} props.items - An array of menu items to display in the sidebar.
 * @param {Function} props.handleDrawerClose - Callback to handle the sidebar closing.
 * @param {boolean} props.isTablet - Indicates whether the sidebar is being displayed on a tablet.
 * @returns {Element} - The rendered menu items.
 */
function Menus({ items = [], handleDrawerClose, isTablet }) {
    const [openItems, setOpenItems] = useState({});

    /**
     * Toggle an item's sub-items.
     * @param {number} index - The index of the item to toggle.
     */
    const toggleItem = (index) => {
        setOpenItems((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    /**
     * Handle navigation to the drawer.
     * @param {boolean} subItems - Indicates whether there are sub-items.
     */
    const toDrawer = (subItems) => {
        if (subItems) {
            return;
        }

        if (isTablet) {
            handleDrawerClose(false);
        }
    };

    return (
        <List className={"menu"}>
            {(items || []).map(({ Icon, name, subItems, route }, index) => (
                <Box key={index}>
                    <NavLink
                        className={({ isActive }) => (isActive && !subItems ? "link active" : "link")}
                        to={!subItems && route}
                        onClick={() => toDrawer(subItems)}
                    >
                        <ListItem onClick={() => toggleItem(index)} className={"list-items"}>
                            {Icon}
                            <ListItemText className={"list-text"} primary={name} />
                            {subItems && (openItems[index] ? <ExpandLess /> : <ExpandMore />)}
                        </ListItem>
                    </NavLink>
                    <Collapse in={openItems[index]} timeout={"auto"} unmountOnExit={true}>
                        <List component={"div"} disablePadding={true}>
                            {(subItems || []).map((subItem, subIndex) => (
                                <NavLink
                                    key={subItem.name}
                                    className={({ isActive }) => (isActive ? "link active" : "link")}
                                    to={`/gym/${subItem.id}/bike-health`}
                                    exact={"true"}
                                    onClick={() => toDrawer()}
                                >
                                    <ListItem key={subIndex} className={"sub-list-items"}>
                                        <ListItemText className={"list-text"} primary={subItem.name} />
                                    </ListItem>
                                </NavLink>
                            ))}
                        </List>
                    </Collapse>
                </Box>
            ))}
        </List>
    );
}

Menus.propTypes = {
    "handleDrawerClose": PropTypes.func.isRequired,
    "isTablet": PropTypes.bool.isRequired,
    "items": PropTypes.arrayOf(
        PropTypes.shape({
            "name": PropTypes.string.isRequired,
            "subItems": PropTypes.arrayOf(
                PropTypes.shape({
                    "name": PropTypes.string.isRequired
                })
            )
        })
    )
};

export default Menus;
