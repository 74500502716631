import i18n, { use } from "i18next";
import { initReactI18next } from "react-i18next";
import { enLanguage } from "./translations/en/language";

use(initReactI18next).init({
    "fallbackLng": "en",
    "interpolation": {
        "escapeValue": false
    },
    "lng": "en",
    "resources": {
        "en": {
            "translation": {
                ...enLanguage
            }
        }
    }
});

export default i18n;
