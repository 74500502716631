import axios from "axios";
import User from "../models/User";

const instance = axios.create({
    "baseURL": "https://vrmapi.victronenergy.com/v2/"
});

instance.interceptors.response.use(
    (res) => {
        return res.data;
    },
    (err) => {
        return err;
    }
);

/**
 * Get installation stats from VRM
 * @param {string} tenantId - Tenant ID
 * @param {User} user - User
 * @param {number} startInHours - Start from (In hours)
 * @returns {Promise} Promise
 */
export const getInstallationStats = (tenantId, user, startInHours = null) => {
    const { siteId, token } = user.getSiteId(tenantId);

    if (siteId && token) {

        instance.defaults.headers.common["x-authorization"] = token;

        let url = `/installations/${siteId}/stats`;
        let interval;

        if (startInHours) {
            if (startInHours <= 6) {
                interval = "15mins";
            } else if (startInHours <= 48) {
                interval = "hours";
            } else if (startInHours <= 720) {
                interval = "days";
            } else if (startInHours <= 2160) {
                interval = "weeks";
            } else if (startInHours <= 8640) {
                interval = "months";
            }

            const start = ((Date.now() - (startInHours * 60 * 60 * 1000)) / 1000).toFixed(0);
            url += `?start=${start}&interval=${interval}`;
        }

        return instance.get(url).then((response) => {
            response.interval = interval;

            return response;
        });
    } else {
        return new Promise((resolve, reject) => {
            reject("Credentials not found.");
        });
    }
};
