import { getGridDateOperators, getGridStringOperators } from "@mui/x-data-grid";
import { getDateAndTime } from "../../utils/date";

/**
 * Definition of columns for a table.
 * @param {object} t - The translation function for localization.
 * @returns {Array} An array of column definitions.
 */
export const columns = (t) => [
    {
        "field": "name",
        "filterOperators": getGridStringOperators().filter(({ value }) => ["contains"].includes(value)),
        "filterable": true,
        "headerName": t("Bikes.name"),
        "sortable": true,
        /**
         * Custom cell renderer for BT Connection Status column.
         * @param {object} root0 - The cell properties object.
         * @param {boolean} root0.value - The boolean value representing BT connection status.
         * @returns {string} The rendered content based on the connection status.
         */
        "valueGetter": ({ value }) => {
            return value;
        },
        "width": 250
    },
    {
        "field": "bt_connection_status",
        "filterable": false,
        "headerName": t("Bikes.bt_connection_status"),
        "sortable": false,
        /**
         * Custom cell renderer for BT Connection Status column.
         * @param {object} root0 - The cell properties object.
         * @param {boolean} root0.value - The boolean value representing BT connection status.
         * @returns {string} The rendered content based on the connection status.
         */
        "valueGetter": ({ value }) => {
            if (value === true) {
                return "Connected";
            } else if (value === false) {
                return "Disconnected";
            } else {
                return "N/A";
            }
        },
        "width": 200
    },
    { "field": "device_id", "filterable": false, "headerName": t("Bikes.device_id"), "sortable": false, "width": 200 },
    {
        "field": "device_type",
        "filterable": false,
        "headerName": t("Bikes.device_type"),
        "sortable": false,
        "width": 200
    },
    {
        "field": "expire_at",
        "filterable": false,
        "headerName": t("Bikes.expire_at"),
        "sortable": false,
        "type": "dateTime",
        /**
         * Custom value getter function.
         * @param {object} params - The parameter object.
         * @param {object} params.value - The value to be processed.
         * @returns {string} The computed value.
         */
        "valueGetter": ({ value }) => {
            return getDateAndTime(value);
        },

        "width": 250
    },
    {
        "field": "time_stamp",
        "filterOperators": getGridDateOperators().filter(({ value }) => ["after"].includes(value)),
        "filterable": true,
        "headerName": t("Bikes.time_stamp"),
        "sortable": true,
        "type": "dateTime",
        /**
         * Custom value getter function.
         * @param {object} params - The parameter object.
         * @param {object} params.value - The value to be processed.
         * @returns {string} The computed value.
         */
        "valueGetter": ({ value }) => {
            return getDateAndTime(value);
        },

        "width": 250
    },
    {
        "field": "os_version",
        "filterable": false,
        "headerName": t("Bikes.os_version"),
        "sortable": false,
        "width": 100
    },
    {
        "field": "short_bike_id",
        "filterable": false,
        "headerName": t("Bikes.short_bike_id"),
        "sortable": false,
        "width": 150
    },
    { "field": "version", "filterable": false, "headerName": t("Bikes.version"), "sortable": false, "width": 150 }
];
