import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import "./ValueCard.scss";

/**
 * Value Card Element to display value with title and unit
 * @param {*} props - Props
 * @returns {Element} - ValueCard Element
 */
const ValueCard = ({ "icon": Icon, title, value, unit, t }) => {
    return (
        <Grid className={"value-card"} item={true} xs={12} sm={4} md={4} lg={4}>
            <Card variant={"outlined"}>
                <div className={"title"}>
                    {Icon ? <Icon /> : null }<span>{title}</span>
                </div>
                <div className={"value-unit"}>
                    <span className={"value"}>{value?.toFixed(1)}</span>
                    <span className={"unit"}>{unit}</span>
                </div>
            </Card>
        </Grid>
    );
};


ValueCard.propTypes = {
    "icon": PropTypes.any.isRequired,
    "t": PropTypes.func.isRequired,
    "title": PropTypes.string.isRequired,
    "unit": PropTypes.string.isRequired,
    "value": PropTypes.number.isRequired
};

export default withTranslation()(ValueCard);