import * as yup from "yup";

/**
 * Creates a Yup schema for validating login credentials.
 * @param {boolean} isResetPassword - A flag indicating whether this schema is for a reset password form.
 * @param {Function} t - A function for localization.
 * @returns {yup.ObjectSchema} - The Yup schema for login validation.
 */
export const loginSchema = (isResetPassword, t) =>
    yup.object({
        "email": yup.string().required(t("Login.emailRequired")),
        ...(isResetPassword && { "password": yup.string().required(t("Login.passwordRequired")) })
    });
