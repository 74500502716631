import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
    "initialState": {
        "open": false
    },
    "name": "drawer",
    "reducers": {
        /**
         * Update the tenants in the state.
         * @param {object} state - The current state.
         * @param {object} action - The action object with a 'payload' containing the new tenants.
         */
        "closeDrawer": (state, action) => {
            state.open = false;
        },

        /**
         * Update the roles in the state.
         * @param {object} state - The current state.
         * @param {object} action - The action object with a 'payload' containing the new roles.
         */
        "openDrawer": (state, action) => {
            state.open = true;
        }
    }
});

// Action creators are generated for each case reducer function
export const { closeDrawer, openDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
