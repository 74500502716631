import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

/**
 * Loader component.
 * @returns {Element} The rendered component.
 */
const Loader = () => {
    const loader = useSelector((state) => state.loader.loading);

    return (
        <div>
            <Backdrop
                sx={{
                    "color": "#fff",
                    /**
                     * Get the z-index for the loader.
                     * @param {object} theme - The MUI theme object.
                     * @returns {number} The z-index value.
                     */
                    "zIndex": (theme) => theme.zIndex.drawer + 1
                }}
                open={loader}
            >
                <CircularProgress color={"inherit"} />
            </Backdrop>
        </div>
    );
};

export default Loader;
