import { getAnalytics } from "firebase/analytics";
import { FirebaseError, initializeApp } from "firebase/app";
import {
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import {
    Timestamp,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc,
    where
} from "firebase/firestore";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../config";
import User from "../models/User";
import { endLoading, startLoading } from "../store/reducer/loader";
import { firebaseErrors } from "../utils/firebaseError";
import { getFromSessionStorage, removeFromStorage, saveToSessionStorage } from "../utils/storage";

const FirebaseContext = createContext(null);

/**
 * Firebase utility hook for accessing Firebase services.
 *
 * The `useFirebase` hook provides a convenient way to access Firebase services
 * throughout your application. It uses the `FirebaseContext` to make Firebase-related
 * functionality available.
 * @returns {Element} - return tool tip for Storage Location Map
 */
export const useFirebase = () => useContext(FirebaseContext);

const firebaseConfig = {
    "apiKey": config.env("REACT_APP_APP_KEY"),
    "appId": config.env("REACT_APP_ID"),
    "authDomain": config.env("REACT_APP_AUTH_DOMAIN"),
    "databaseURL": config.env("REACT_APP_DB_URL"),
    "measurementId": config.env("REACT_APP_MEASUREMENT_ID"),
    "messagingSenderId": config.env("REACT_APP_MESSAGING_SENDER_ID"),
    "projectId": config.env("REACT_APP_PROJECT_ID"),
    "storageBucket": config.env("REACT_APP_STORAGE_BUCKET")
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);

/**
 * @param {*} props - The props for the FirebaseProvider component.
 * @returns {Element} - return tool tip for Storage Location Map
 */
export const FirebaseProvider = withTranslation()(({ children, t }) => {
    const [user, setUser] = useState(undefined);
    const [firmwareVersions, setFirmwareVersions] = useState([]);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(firebaseAuth, (user) => {
            const sessionStorageUser = getFromSessionStorage("user", true);
            if (user && sessionStorageUser) {
                setUser(new User(sessionStorageUser));
            } else {
                setUser(null);
            }
        });
    }, []);

    /**
     * Function for sign up a user with email and password.
     * This function creates a new user account with the provided email and password.
     * @param {string} email - The user's email address.
     * @param {string} password - The user's password.
     * @returns {Promise} A promise that resolves when the user is successfully signed up.
     * @throws {Error} If there is an error during the sign-up process, an error is thrown with details.
     */
    const signupUserWithEmailAndPassword = (email, password) =>
        createUserWithEmailAndPassword(firebaseAuth, email, password);

    /**
     * Function for sign in a user with email and password.
     * This function creates a new user account with the provided email and password.
     * @param {string} email - The user's email address.
     * @param {string} password - The user's password.
     * @returns {Promise} A promise that resolves when the user is successfully signed up.
     * @throws {Error} If there is an error during the sign-up process, an error is thrown with details.
     */
    const signInUserWithEmailAndPass = (email, password) => signInWithEmailAndPassword(firebaseAuth, email, password);

    /**
     * Handle sending a password reset email.
     * @param {string} email - The email address of the user who needs a password reset.
     * @returns {Promise<void>} - A promise that resolves when the email is sent successfully.
     */
    const handleToForgotPass = (email) => sendPasswordResetEmail(firebaseAuth, email);

    /**
     * Handle logout user.
     *  @returns {Promise<void>} - A promise that resolves when the email is sent successfully.
     */
    const logout = () => signOut(firebaseAuth);

    /**
     * Show toast with detailed message if in dev mode, else generic error
     * @param {FirebaseError} firebaseError - Error from firebase
     */
    const handleFirebaseError = (firebaseError) => {
        if (config.env("REACT_APP_ENV") === "development") {
            // eslint-disable-next-line no-console
            console.error("Firebase error - ", firebaseError.message);
            toast.error(firebaseError?.message);
        } else {
            toast.error(firebaseErrors[firebaseError.code] || "Something went wrong");
        }
    };

    /**
     * Retrieves documents from a Firestore collection with pagination, sorting, and searching.
     * @param {object} params - An object containing the parameters for fetching bike data.
     * @param {string} params.currentPage - The current page number.
     * @param {string} params.pageSizeData - The number of documents to retrieve per page.
     * @param {string} params.sortedColumn - The column to sort the bikes by.
     * @param {string} params.sortedOrder - The order in which the bikes should be sorted.
     * @param {number} params.totalDocs - Total count
     * @param {string} params.lastUUID - Last Bike UUID
     * @param {string} params.filters - list of all filters of query and columns .
     * @returns {Promise<{ data: Array<object>, nextPage: number | null }>} A Promise
     */
    const fetchBikeDataWithLogs = async ({
        currentPage,
        pageSizeData,
        sortedColumn,
        sortedOrder,
        totalDocs = null,
        lastUUID = null,
        filters
    }) => {
        try {
            const bikeCollectionRef = collection(firestore, "bike");

            let bikeQuery = query(bikeCollectionRef);

            const bikeQueryForSort = getFilterAndSortData(
                bikeQuery,
                orderBy(sortedColumn, sortedOrder),
                filters,
                sortedColumn,
                sortedOrder
            );
            bikeQuery = bikeQueryForSort;
            bikeQuery = await getPaginationData(bikeQuery, pageSizeData, currentPage, lastUUID, "bike");
            totalDocs = totalDocs || (await getTotalPage(query(bikeQueryForSort)));

            const bikeSnapshot = await getDocs(bikeQuery);

            const bikeData = [];

            for (const bikeDoc of bikeSnapshot.docs) {
                const bikeDocData = { "docId": bikeDoc.id, ...bikeDoc.data() };
                const bikeId = bikeDocData.uuid;
                
                const firmwareCollectionRef = collection(firestore, "firmware");
                bikeDocData.firmware = (await getDoc(doc(firmwareCollectionRef, bikeId))).data();

                const logsCollectionRef = collection(firestore, "Logs");
                const logsQuery = query(
                    logsCollectionRef,
                    where("bike_id", "==", bikeId),
                    orderBy("time_stamp", "desc"),
                    limit(1)
                );
                const logsSnapshot = await getDocs(logsQuery);
                const logsData = [];
                for (const logsDoc of logsSnapshot.docs) {
                    logsData.push(logsDoc.data());
                }

                const combinedData = {
                    "bike": bikeDocData,
                    "logs": logsData[0]
                };

                bikeData.push(combinedData);
            }

            return { bikeData, totalDocs };
        } catch (error) {
            handleFirebaseError(error);
        }
    };

    /**
     * Fetch logs by bike UUID.
     * @param {object} params - Parameters for fetching logs.
     * @param {string} params.bikeUuid - The UUID of the bike to fetch logs for.
     * @param {string} params.currentPage - The current page number.
     * @param {string} params.pageSizeData - The number of documents to retrieve per page.
     * @param {string} params.lastUUID - Last Bike UUID
     * @param {string} params.sortedColumn - The sort query's column.
     * @param {string} params.sortedOrder - The sort order query's .
     * @param {string} params.filters - list of all filters of query and columns .
     * @returns {Promise<Array>} A Promise that resolves to an array of logs data.
     */
    const fetchLogsByUuid = async ({
        bikeUuid,
        currentPage,
        lastUUID,
        pageSizeData,
        sortedColumn,
        sortedOrder,
        filters = []
    }) => {
        dispatch(startLoading());
        try {
            const logsCollectionRef = collection(firestore, "Logs");

            let logsQuery = query(logsCollectionRef);
            logsQuery = query(logsQuery, where("bike_id", "==", bikeUuid));

            logsQuery = getFilterAndSortData(
                logsQuery,
                orderBy(sortedColumn, sortedOrder),
                filters,
                sortedColumn,
                sortedOrder
            );

            const totalDocs = await getTotalPage(query(logsQuery));
            logsQuery = await getPaginationData(logsQuery, pageSizeData, currentPage, lastUUID, "Logs");

            const logsSnapshot = await getDocs(logsQuery);

            const logsData = [];
            for (const logsDoc of logsSnapshot.docs) {
                logsData.push({ "docId": logsDoc.id, ...logsDoc.data() });
            }

            return { logsData, totalDocs };
        } catch (error) {
            handleFirebaseError(error);

            return [];
        } finally {
            dispatch(endLoading());
        }
    };

    /**
     * Apply filters and sorting to a Firestore query.
     * @param {query} logsQuery - The Firestore query to which filters and sorting are applied.
     * @param {orderBy} defaultSortOrder - The Firestore orderBy
     * @param {Array} filters - An array of filter objects.
     * @param {string} sortedColumn - The column by which to sort the data.
     * @param {string} sortedOrder - The sorting order ("asc" or "desc").
     * @returns {query} - The modified Firestore query with filters and sorting applied.
     */
    const getFilterAndSortData = (logsQuery, defaultSortOrder, filters, sortedColumn, sortedOrder) => {
        let doNotAddSort = false;
        filters.forEach((filter) => {
            if (filter.searchQuery !== undefined && filter.searchColumn) {
                const queryData =
                    filter.searchColumn === "time_stamp"
                        ? Timestamp.fromDate(new Date(filter.searchQuery))
                        : filter.searchQuery;

                if (filter.searchColumn === sortedColumn) {
                    doNotAddSort = true;
                }

                if (filter.searchType === "contains") {
                    logsQuery = query(
                        logsQuery,
                        orderBy(filter.searchColumn, doNotAddSort ? sortedOrder : "asc"),
                        where(filter.searchColumn, ">=", queryData),
                        where(filter.searchColumn, "<=", queryData + "~")
                    );
                } else {
                    logsQuery = query(
                        logsQuery,
                        orderBy(filter.searchColumn, doNotAddSort ? sortedOrder : "asc"),
                        where(filter.searchColumn, filter.searchType, queryData)
                    );
                }
            }
        });

        if (!doNotAddSort) {
            logsQuery = query(logsQuery, defaultSortOrder);
        }

        return logsQuery;
    };

    /**
     * Get pagination data for a Firestore query.
     * @param {query} logsQuery - The Firestore query to which pagination is applied.
     * @param {number} pageSizeData - The page size to limit the number of results per page.
     * @param {number} currentPage - The current page number.
     * @param {string|null} lastUUID - The UUID of the last document for pagination.
     * @param {string|null} collectionName - Collection name
     * @returns {query} - The modified Firestore query with pagination applied.
     */
    const getPaginationData = async (logsQuery, pageSizeData, currentPage, lastUUID, collectionName) => {
        if (pageSizeData) {
            logsQuery = query(logsQuery, limit(pageSizeData));
        }

        if (currentPage > 0 && lastUUID && collectionName) {
            const questionCollectionRef = collection(firestore, collectionName);
            const afterDoc = await getDoc(doc(questionCollectionRef, lastUUID));

            logsQuery = query(logsQuery, startAfter(afterDoc));
        }

        return logsQuery;
    };

    /**
     * Get the total number of pages based on the total number of documents and a query to fetch total size.
     * @param {query} totalSizeQuery - The Firestore query to fetch the total size of documents.
     * @returns {number} - The total number of pages or 0 if `totalDocs` is falsy.
     */
    const getTotalPage = async (totalSizeQuery) => {
        const totalDocs = (await getDocs(totalSizeQuery)).size;

        return totalDocs || 0;
    };

    /**
     * Get data for a specific user from a Firestore collection.
     * @param {string} collectionName - The name of the Firestore collection to query.
     * @param {string} id - The ID of the user to retrieve.
     * @param {object|null} tenantFirebaseApp -Firestore object for tenant
     * @returns {Promise<object | null>} A Promise that resolves to the user data or null if not found.
     */
    const getDataByIdAndDB = async (collectionName, id, tenantFirebaseApp = null) => {
        const localFirestore = tenantFirebaseApp ? getFirestore(tenantFirebaseApp) : firestore;
        const userDocRef = doc(localFirestore, collectionName, id);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            // User document found, access the data
            const userData = userDocSnapshot.data();

            return userData;
        } else {
            // User document doesn't exist
            return null;
        }
    };

    /**
     * Log in a user.
     * @param {object} data - User data, including email and password.
     * @param {boolean} isResetPassword - Flag indicating if it's a password reset.
     * @param {Function} t - Translation function for localization.
     */
    const loginUser = async (data, isResetPassword, t) => {
        const { email, password } = data;

        dispatch(startLoading());
        try {
            if (!isResetPassword) {
                const loginUser = await signInUserWithEmailAndPass(email, password);

                const userData = await getDataByIdAndDB("user", loginUser.user.uid);
                if (!userData?.is_active) {
                    toast.error(t("Login.deActiveUser"));

                    return;
                }

                if (userData?.email !== email) {
                    toast.error(t("Login.notConfigureUser"));

                    return;
                }
                const userClass = new User({
                    "email": userData.email,
                    "fullName": userData.full_name,
                    "idToken": loginUser._tokenResponse.idToken,
                    "navigate": navigate,
                    "password": password,
                    "profilePhoto": userData.profile_photo,
                    "refreshToken": loginUser._tokenResponse.refreshToken,
                    "roles": userData.role,
                    "selectedTenant": {},
                    "tenants": userData.tenants
                });
                if (userClass.isUserNotHaveAnyRole()) {
                    toast.error(t("Login.userNotHaveRoleMsg"));

                    return;
                }

                await getAllTenantsList(userClass);
                setUser(userClass);
                delete userClass.password;
                saveToSessionStorage("user", userClass);

                navigateAsPerRole(userClass);
            } else {
                await handleToForgotPass(email);
                toast.success(t("Login.resetPasswordMsg"));
            }
        } catch (error) {
            handleFirebaseError(error);
        } finally {
            dispatch(endLoading());
        }
    };

    /**
     * Navigate to allowed screen
     * @param {User} userClass - User class with tenants list
     */
    const navigateAsPerRole = async (userClass) => {
        if (userClass.isSuperAdmin()) {
            navigate("/bikes");
        } else if (userClass.tenantList.length) {
            const firstModule = userClass.tenantList[0]?.accessScreens[0];
            if (firstModule) {
                navigate(`/gym/${userClass.tenantList[0].id}/${firstModule}`);
            } else {
                toast.error(t("Login.notConfigureUser"));    
            }
        } else {
            toast.error(t("Login.notConfigureUser"));
        }
    };

    /**
     * Get a list of all tenants with their data.
     * @param {User} userClass - User class object
     */
    const getAllTenantsList = async (userClass) => {
        try {
            const allTenants = await Promise.all(
                userClass.getAllTenantsName().map(async (tenant) => {
                    const firebaseApp = initializeApp(firebaseConfig, tenant);
                    const auth = getAuth(firebaseApp);
                    auth.tenantId = tenant;
                    await signInWithEmailAndPassword(auth, userClass.email, userClass.password);

                    const tenantData = await getDataByIdAndDB("tenant", tenant, firebaseApp);
                    const locations = await Promise.all(
                        userClass.tenants[tenant].map(async (location) => {
                            return await getDataByIdAndDB(
                                `tenant/${tenant}/location`,
                                location,
                                firebaseApp
                            );
                        })
                    );
                    const roleModuleMapping = await getDataByIdAndDB(
                        `tenant/${tenant}/role_module_mapping`,
                        userClass.roles,
                        firebaseApp
                    );
                    const accessScreens = roleModuleMapping?.modules || [];

                    return { ...tenantData, accessScreens, "id": tenant, locations };
                })
            );
            userClass.tenantList = allTenants || [];
        } catch (error) {
            toast.error(firebaseErrors[error.code]);
            navigate("/login/");
        }
    };

    /**
     * Logout User
     */
    const logoutUser = async () => {
        dispatch(startLoading());
        try {
            await logout();
            removeFromStorage();
            navigate("/login");
        } catch (error) {
            handleFirebaseError(error);
        } finally {
            dispatch(endLoading());
        }
    };

    /**
     * Get all data about bikes.
     * @param {object} params - An object containing the parameters for fetching bike data.
     * @param {string} params.currentPage - The current page number.
     * @param {string} params.pageSizeData - The number of documents to retrieve per page.
     * @param {string} params.searchQuery - The search query to filter bikes.
     * @param {string} params.sortedColumn - The column to sort the bikes by.
     * @param {string} params.sortedOrder - The order in which the bikes should be sorted.
     * @param {number} params.totalDocs - Total count.
     * @param {string} params.lastUUID - Last Bike UUID
     * @param {string} params.filters - list of all filters of query and columns .
     * @returns {Promise<Array>} A promise that resolves to an array of bike data.
     */
    const getBikes = async ({
        currentPage,
        pageSizeData,
        searchQuery,
        sortedColumn,
        sortedOrder,
        totalDocs = null,
        lastUUID = null,
        filters
    }) => {
        dispatch(startLoading());
        try {
            const bikes = await fetchBikeDataWithLogs({
                currentPage,
                filters,
                lastUUID,
                pageSizeData,
                searchQuery,
                sortedColumn,
                sortedOrder,
                totalDocs
            });

            return bikes;
        } catch (error) {
            handleFirebaseError(error);

            return [];
        } finally {
            dispatch(endLoading());
        }
    };

    /**
     * Change firmware version in firebase for list of UUIDs
     * @param {Array} UUIDs - List of bike UUIDs for which version is to be changed
     * @param {string} newVersion - New Version to be selected
     * @param {Array} bikeData - List of bikes
     * @returns {boolean} true, if update successful
     */
    const changeFirmwareVersion = async (UUIDs, newVersion, bikeData) => {
        dispatch(startLoading());
        let success = false;
        
        try {
            UUIDs.forEach(async (bikeId) => {
                const selectedBike = bikeData.find((bike) => bike.id === bikeId);

                const firmwareCollectionRef = doc(firestore, "firmware", bikeId);
                setDoc(firmwareCollectionRef, {
                    "current_firmware": selectedBike.current_firmware,
                    "target_firmware": newVersion
                });
            });
            success = true;
            toast.success(t("Bikes.firmware_updated_successfully"));
        } catch (error) {
            handleFirebaseError(error);
        } finally {
            dispatch(endLoading());
        }

        return success;
    };

    /**
     * Get all available firmware versions
     */
    const getAllFirmwareVersions = useCallback(async () => {
        if (!firmwareVersions.length) {
            const firmwareCollectionRef = collection(firestore, "firmware");
            const { versions } = (await getDoc(doc(firmwareCollectionRef, "firmware_versions"))).data();

            setFirmwareVersions(versions);
        }
    }, [firmwareVersions.length]);

    /**
     * Edit the location of a tenant in Firestore.
     * @param {object} root0 - An object containing the following properties:
     * @param {string} root0.docId - The unique identifier of the Firestore document to be updated.
     * @param {string} root0.tenantID - The ID of the tenant whose location is being updated.
     * @param {object} root0.locationID - An object representing the updated location data.
     * @param {Function} root0.t - Function for translation
     */
    const editLocationOfTenant = async ({ docId, tenantID, locationID, t }) => {
        dispatch(startLoading());

        try {
            const docRef = doc(firestore, "bike", docId);
            updateDoc(docRef, {
                "location_id": locationID,
                "tenant_id": tenantID
            });
            toast.success(t("Dashboard.update_bike_log"));
        } catch (error) {
            handleFirebaseError(error);
        } finally {
            dispatch(endLoading());
        }
    };

    return (
        <FirebaseContext.Provider
            value={{
                analytics,
                changeFirmwareVersion,
                editLocationOfTenant,
                fetchBikeDataWithLogs,
                fetchLogsByUuid,
                firebaseApp,
                firmwareVersions,
                getAllFirmwareVersions,
                getBikes,
                getDataByIdAndDB,
                handleToForgotPass,
                loginUser,
                logoutUser,
                navigateAsPerRole,
                signInUserWithEmailAndPass,
                signupUserWithEmailAndPassword,
                user
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
});

FirebaseProvider.propTypes = {
    "children": PropTypes.node.isRequired
};
