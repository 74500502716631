import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import AllRoutes from "./common/AllRoutes/AllRoutes";
import Loader from "./components/Loader/Loader";

/**
 * Main application component for the Energym app.
 *
 * The `App` component serves as the entry point for the Energym application.
 * It initializes the theme based on the user's preference for dark or light mode
 * and renders the core application content.
 * @returns {Element} - The root element of the Energym application.
 */
const App = () => {
    const theme = useMemo(
        () =>
            createTheme({
                "palette": {
                    "background": {
                        "default": "#000"
                    },
                    "mode": "dark",
                    "primary": {
                        "main": "#1DA538" // Set your custom primary color here
                    }
                }
            }),
        []
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Loader />
            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={true}
                draggable={true}
                pauseOnHover={true}
                theme={"dark"}
            />
            <AllRoutes />
        </ThemeProvider>
    );
};

export default App;
