import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AiOutlineHeart } from "react-icons/ai";
import { BsBatteryCharging, BsBicycle } from "react-icons/bs";
import { GrStatusGoodSmall } from "react-icons/gr";
import { MdMiscellaneousServices, MdOutlineShutterSpeed } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Bike from "../../assets/images/bike-image.png";
import "./BikeTile.scss";

/**
 * A component to display information about a bike.
 * @param {*} props - The props object.
 * @returns {Element} The JSX element for the BikeTile component.
 */
const BikeTile = ({ reGen, uuid, bikeStatus, bikeMQTTData, version, isSuperAdmin }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    /**
     * handle to move all logs of bike's screen
     */
    const handleToViewAllLogs = () => {
        if (isSuperAdmin && uuid) {
            navigate(`/bikes/${uuid}`);
        }
    };

    /**
     * Get data from bike mqtt object
     * @param {string} type - Type of data
     * @param {string} key - Data key
     * @returns {string} Value, if exists else N/A
     */
    const getBikeMQTTValue = (type, key) => {
        if (
            bikeMQTTData &&
            Object.hasOwn(bikeMQTTData, type)
        ) {
            const bikeTypeData = bikeMQTTData[type];
            if (Object.hasOwn(bikeTypeData, key)) {
                return bikeMQTTData[type][key];
            }
        }

        return "N/A";
    };

    /**
     * Check status from mqtt, if connection made, else show firebase status
     * @returns {boolean} true, if connected, else false
     */
    const getStatus = () => {
        if (getBikeMQTTValue("status", "firmwareVersion") !== "N/A") {
            return true;
        }

        return bikeStatus;
    };

    return (
        <Box className={`bike-tile ${isSuperAdmin && "super-tiles"}`} onClick={handleToViewAllLogs}>
            <Typography className={"title"}>
                {t("BikeTiles.reGen")} {reGen}
            </Typography>
            <Box className={"tiles-info-container"}>
                <Box className={"tiles-info"} title={uuid}>
                    <BsBicycle size={"1.2em"} className={"tiles-icon"} /> {t("BikeTiles.uuid")}:
                    <span className={"text"}>{uuid}</span>
                </Box>

                <Box className={"tiles-info"}>
                    <AiOutlineHeart size={"1.2em"} className={"tiles-icon"} /> {t("BikeTiles.bikeStatus")}:
                    <GrStatusGoodSmall className={`icon-heart ${getStatus() ? "active" : "deActive"}`} />
                </Box>

                <Box className={"tiles-info "} title={version}>
                    <MdMiscellaneousServices size={"1.2em"} className={"tiles-icon"} />
                    {t("BikeTiles.appVersion")}:<span className={"text"}>{version}</span>
                </Box>
                
                <Box className={"tiles-info "} title={getBikeMQTTValue("status", "firmwareVersion")}>
                    <MdMiscellaneousServices size={"1.2em"} className={"tiles-icon"} />
                    {t("BikeTiles.firmwareVersion")}:
                    <span className={"text"}>{getBikeMQTTValue("status", "firmwareVersion")}</span>
                </Box>
                
                <Box className={"tiles-info "} title={getBikeMQTTValue("metric", "cadance")}>
                    <MdOutlineShutterSpeed size={"1.2em"} className={"tiles-icon"} />
                    {t("BikeTiles.cadence")}: 
                    <span className={"text"}>{getBikeMQTTValue("metric", "cadance")}</span>
                </Box>

                <Box className={"tiles-info "} title={getBikeMQTTValue("tabletStatus", "battery_percentage")}>
                    <BsBatteryCharging size={"1.2em"} className={"tiles-icon"} />
                    {t("BikeTiles.batteryPercentage")}:
                    <span className={"text"}>{getBikeMQTTValue("tabletStatus", "battery_percentage")}</span>
                </Box>
            </Box>
            <Box className={"tiles-img"}>
                <img src={Bike} alt={t("BikeTiles.bike")} />
            </Box>
        </Box>
    );
};

// Define PropTypes validation for the component
BikeTile.propTypes = {
    "bikeMQTTData": PropTypes.object,
    "bikeStatus": PropTypes.bool.isRequired,
    "isSuperAdmin": PropTypes.bool.isRequired,
    "reGen": PropTypes.string.isRequired,
    "uuid": PropTypes.string.isRequired,
    "version": PropTypes.string.isRequired
};

export default BikeTile;
