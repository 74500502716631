import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFirebase } from "../../../context/Firebase";
import { openDrawer } from "../../../store/reducer/drawer";
import "./Header.scss";

/**
 * Header component.
 * @returns {Element} The rendered component.
 */
const Header = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [title, setTitle] = useState();
    const { logoutUser, user } = useFirebase();
    const navigate = useNavigate();

    const { pathname } = useLocation();
    const { uuid } = useParams();

    const dispatch = useDispatch();
    const open = useSelector((state) => state.drawer.open);

    /**
     * Handles the click event to open the menu.
     * @param {Event} event - The click event.
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Close user menu
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Logout user from app
     */
    const handleLogout = async () => {
        await logoutUser();
    };

    /**
     * Opens the sidebar drawer.
     */
    const openSidebarDrawer = () => {
        dispatch(openDrawer());
    };

    /**
     * Handle to go back
     */
    const handleGoToBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const parts = pathname.split("/");
        if (pathname.includes("bikes") && uuid) {
            setTitle(t("Header.titles.bike", { "bikeUuId": uuid }));
        } else {
            setTitle(t(`Header.titles.${parts[parts.length - 1]}`));
        }
    }, [pathname, t, uuid]);

    return (
        <Box className={"header"}>
            <Box className={"title"}>
                {!open && (
                    <Box>
                        <IconButton size={"large"} onClick={openSidebarDrawer}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                )}
                {pathname.includes("bikes") && uuid && (
                    <IconButton onClick={handleGoToBack} size={"large"}>
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Box>{title}</Box>
            </Box>
            <Box className={"nav"}>
                <Box>
                    <IconButton
                        size={"large"}
                        aria-controls={"avatar-menu"}
                        aria-haspopup={"true"}
                        onClick={handleClick}
                    >
                        {user?.profilePhoto ? (
                            <Avatar src={user.profilePhoto}></Avatar>
                        ) : (
                            <Avatar>
                                {user?.fullName
                                    .split(" ")
                                    .slice(0, 2)
                                    .map((word) => word[0])
                                    .join("")}
                            </Avatar>
                        )}
                    </IconButton>
                    <Menu
                        id={"avatar-menu"}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            "horizontal": "right",
                            "vertical": "bottom"
                        }}
                        transformOrigin={{
                            "horizontal": "right",
                            "vertical": "top"
                        }}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleLogout}> {t("Logout.title")} </MenuItem>
                    </Menu>
                </Box>
            </Box>
        </Box>
    );
};

export default Header;
